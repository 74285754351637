/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-useless-fragment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/* eslint-disable consistent-return */
// Disabled due `domToReact` internal logic

import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';

import Link from 'Component/Link';
import { HtmlComponent as SourceComponent } from 'SourceComponent/Html/Html.component';

// Used to load LCP elements as high priority
export const HIGH_PRIORITY_ELEMENTS = ['widget', 'img'];

/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace Satisfly/Component/Html/Component */
export class HtmlComponent extends SourceComponent {
    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget,
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks,
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages,
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput,
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript,
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle,
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable,
        },
    ];

    /**
     * Replace links to native React Router links
     * @param  {{ attribs: Object, children: Array }}
     * @return {void|JSX} Return JSX if link is allowed to be replaced
     * @memberof Html
     */
    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;

        if (href) {
            const isSpecialLink = (value) => /^(sms|tel|mailto):/i.test(value);

            if (!isSpecialLink(href)) {
                return (
                    <Link
                      onClick={ this.scrollToTopFunction }
                      { ...attributesToProps({ ...attrs, to: href }) }
                    >
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }
        }

        return domToReact(children, this.parserOptions);
    }
}

export default HtmlComponent;
