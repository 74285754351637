/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import NoMatch from 'Route/NoMatch';
import { CmsPageComponent as SourceCmsPageComponent } from 'SourceRoute/CmsPage/CmsPage.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import './CmsPage.override.style';

/** @namespace Satisfly/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    componentDidMount() {
        setLoadedFlag();
    }

    render() {
        const {
            cmsPage,
            isBreadcrumbsActive,
            isLoading,
            isHome,
        } = this.props;
        const { page_width, title, content } = cmsPage;

        if (!isLoading && !title && !content) {
            return <NoMatch />;
        }

        return (
                <main
                  block="CmsPage"
                  mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive, pureCms: !isHome } }
                >
                    <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                        { this.renderHeading() }
                        <div block="CmsPage" elem="Content">
                            { this.renderContent() }
                        </div>
                    </div>
                </main>
        );
    }
}

export default CmsPageComponent;
