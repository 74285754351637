/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    CmsPageContainer as SourceContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace Satisfly/Route/CmsPage/Container */
export class CmsPageContainer extends SourceContainer {
    static defaultProps = {
        pageIds: -1,
        pageIdentifiers: '',
        isOnlyPlaceholder: false,
        isBreadcrumbsActive: true,
        isHome: false,
    };

    containerProps() {
        const {
            isBreadcrumbsActive, isLoading, cmsPage, isHome,
        } = this.props;

        return {
            isBreadcrumbsActive,
            isLoading,
            cmsPage,
            isHome,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
