/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import Footer from 'Component/Footer';
import InstallPrompt from 'Component/InstallPrompt';
import CmsPage from 'Route/CmsPage';
import {
    HomePageContainer as SourceContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';

import './HomePage.style';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Satisfly/Route/HomePage/Container */
export class HomePageContainer extends SourceContainer {
    render() {
        return (
            <div block="HomePage">
                <InstallPrompt />
                <CmsPage { ...this.containerProps() } isHome />
                <Footer isVisibleOnMobile />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
